import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    DeviceType,
    selectIsConnectedToRoom,
    selectLocalVideoTrackID,
    selectVideoTrackByID,
    useAVToggle,
    useHMSActions,
    useHMSStore,
    useDevices,
    selectIsLocalVideoEnabled
} from "@100mslive/react-sdk";
import { MicOffIcon, MicOnIcon, VideoOnIcon } from "@100mslive/react-icons";
import {Tooltip, StyledVideoTile, Video, Box, Flex, Dropdown, Text} from "@100mslive/roomkit-react";
import {ToastManager} from "./Toast/ToastManager";
import IconButton from "../IconButton";
import {isMacOS, UI_SETTINGS} from "../common/constants";
import getLocationPermission from "../services/locationService";
import { useNavigation } from "./hooks/useNavigation";
import { useUISettings } from "./AppData/useUISettings";
import { useDropdownSelection } from "./hooks/useDropdownSelection";
import { DialogDropdownTrigger } from "../primitives/DropdownTrigger";

export const AudioVideoToggle = () => {
    const {isLocalVideoEnabled, isLocalAudioEnabled, toggleAudio, toggleVideo} =
        useAVToggle();
    const navigate = useNavigation();
    const actions = useHMSActions();
    const videoTracKId = useHMSStore(selectLocalVideoTrackID);
    const localVideoTrack = useHMSStore(selectVideoTrackByID(videoTracKId));
    const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
    const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();
    const { videoInput, audioInput } = allDevices;
    const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
    const trackSelector = selectVideoTrackByID(videoTracKId);
    const track = useHMSStore(trackSelector);
    const mirrorLocalVideo = useUISettings(UI_SETTINGS.mirrorLocalVideo);

    useEffect(() => {
        const roomInfo = localStorage.getItem('roomInfo');
        if(navigator.onLine){
          getLocationPermission().then(() => {
          }).catch(() => {
              navigate("/error/location-error");
          })    
        }
    })

    return (
        <Fragment>
            {toggleAudio ? (
                <Tooltip
                    title={`Turn ${isLocalAudioEnabled ? "off" : "on"} audio (${
                        isMacOS ? "⌘" : "ctrl"
                    } + d)`}
                >
                    <IconButton
                        active={isLocalAudioEnabled}
                        onClick={toggleAudio}
                        key="toggleAudio"
                        data-testid="audio_btn"
                    >
                        {!isLocalAudioEnabled ? (
                            <MicOffIcon data-testid="audio_off_btn"/>
                        ) : (
                            <MicOnIcon data-testid="audio_on_btn"/>
                        )}
                    </IconButton>
                </Tooltip>
            ) : null}
            {localVideoTrack?.facingMode && isConnectedToRoom ? (
                <Fragment>
                {videoInput?.length ? (
                    <Fragment>
                      <DeviceSelector
                        devices={videoInput}
                        icon={<VideoOnIcon />}
                        selection={selectedDeviceIDs.videoInput}
                        onChange={deviceId =>
                          updateDevice({
                            deviceId,
                            deviceType: DeviceType.videoInput,
                          })
                        }
                      />
                    </Fragment>
                  ) : null}
                  </Fragment>
            ) : null}
        </Fragment>
    );
};

const DeviceSelector = ({
    devices,
    selection,
    onChange,
    icon,
    children = null,
  }) => {
    const [open, setOpen] = useState(false);
    const selectionBg = useDropdownSelection();
    const ref = useRef(null);
    return (
      <Box>
        <Flex
          align="center"
          css={{
            gap: "$4",
            "@md": {
              flexDirection: children ? "column" : "row",
              alignItems: children ? "start" : "center",
            },
          }}
        >
          <Box
            css={{
              position: "relative",
              flex: "1 1 0",
              w: "100%",
              minWidth: 0,
              "@md": {
                mb: children ? "$8" : 0,
              },
            }}
          >
            <Dropdown.Root open={open} onOpenChange={setOpen}>
              <DialogDropdownTrigger
                ref={ref}
                css={{
                  ...(children
                    ? {
                        flex: "1 1 0",
                        minWidth: 0,
                      }
                    : {}),
                }}
                // icon={icon}
                title={
                //   devices.find(({ deviceId }) => deviceId === selection)?.label ||
                  "Switch Camera"
                }
                open={open}
              />
              <Dropdown.Portal>
                <Dropdown.Content
                  align="start"
                  sideOffset={8}
                  css={{ w: ref.current?.clientWidth, zIndex: 1000 }}
                >
                  {devices.map(device => {
                    return (
                      <Dropdown.Item
                        key={device.label}
                        onSelect={() => onChange(device.deviceId)}
                        css={{
                          px: "$9",
                          bg:
                            device.deviceId === selection
                              ? selectionBg
                              : undefined,
                        }}
                      >
                        {device.label}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Content>
              </Dropdown.Portal>
            </Dropdown.Root>
          </Box>
        </Flex>
      </Box>
    );
  };