import React, { useEffect, useState } from "react";
import { Flex, flexCenter, Loading, styled, Text } from "@100mslive/roomkit-react";
import { useParams } from "react-router-dom";
import { LargeLogo } from "./Header/HeaderComponents";
import validateToken from "../services/authService";
import { getVKYCNetworkDetails, getVKYCTaskDetails } from "../services/vkycService";
import { useNavigation } from "./hooks/useNavigation";
import getLocationPermission from "../services/locationService";
import DetectRTC from "detectrtc";
import apiCallerService from "../services/apiCallerService";

function WelcomePage({ getDetails }) {
  const navigate = useNavigation();
  const { taskId, sessionId } = useParams();
  const [isLocation, setIsLocation] = useState(false);
  const [detectrtc, setDetectRtc] = useState('')
  const [systemReport, setSystemReport] = useState({});
  const [isSystemReport, setIsSystemReport] = useState(false);
  const [isCompatibilityReport, setIsCompatibilityReport] = useState(false);
  const [isNetworkReport, setIsNetworkReport] = useState(false);
  const [isRoomDetails, setIsRoomDetails] = useState(false);
  const [compatibilityReport, setCompatibilityReport] = useState({});
  const [networkReport, setNetworkReport] = useState({});
   const [messagesReceived, setMessagesReceived] = useState(0);
   const [error, setError] = useState();

  const [options, setOptions] = useState({
    iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
    logger: {
      log() {
        //log
      }, error() {
        //error
      }, warn() {
        //warn
      }, info() {
        //info
      }
    }
  });

  const navOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0
  };
  
  useEffect(() => {
    if (sessionId && taskId) {
      localStorage.setItem("kycTaskId", taskId);
        validateToken(sessionId)
          .then(token => {
            // fetch VKYC Details
            getVKYCTaskDetails(taskId).then((roomDetails) => {
                if (roomDetails && roomDetails.data.hasOwnProperty('channel_name')) {
                  localStorage.setItem('roomInfo', JSON.stringify(roomDetails));
                  setIsRoomDetails(true)
                }
            });
          })
          .catch(error => {
            if(error?.response?.status == 401)
              setError('Session Expired')
          });
    }
  }, [
    sessionId, taskId
  ]);

  useEffect(() => {
    if(isRoomDetails == true)
    generateReport(sessionId).then((rep) => console.log(rep)).catch((err) => console.log(err));

  }, [isSystemReport, isLocation, isCompatibilityReport, isNetworkReport, isRoomDetails])

  const generateReport = async (sessionId) => {
     try{
      DetectRTC.load(() => {
        setDetectRtc(DetectRTC);
         buildSystemReport().then(() => {
          if (isSystemReport == true) {
            buildCompatibilityReport().then(() => {
              if (isCompatibilityReport == true && isLocation == true) {
                buildNetworkReport().then(()=> {
                  if(isNetworkReport == true){
                    let dsData = {
                      sessionId: sessionId ? sessionId : '',
                      system: systemReport,
                      compatibility: compatibilityReport,
                      network: networkReport,
                    }
                    apiCallerService(`cases/'${sessionId}'/vkyc/ds-data`, "POST", dsData).then((resp) => {
                      if(compatibilityReport?.location_permission == 'granted') 
                      navigate("/preview/" + JSON.parse(localStorage.getItem('roomInfo'))?.data?.channel_name + '/' + 'agent');
                    })
                    // }).then((data) => console.log(data))
                    .catch((err) => console.log(err))
                  }
                })
              }
            })
          }
        })
      })
    }
      catch (error) {
      alert('Error while running compatibility check on your device.');
    }
  }

  const registerEvents = async (eventName, eventValue) => {
    var url = 'cases/' + JSON.parse(localStorage.getItem('roomInfo'))?.data?.uuid + '/vkyc/register-event/' + JSON.parse(localStorage.getItem('roomInfo'))?.data?.channel_name + '/' + eventName + '/' + eventValue;
    apiCallerService(url, 'GET')  


  };

  const checkForMicrophoneAndCameraPermission = async () => {
    if (isCompatibilityReport == false) {
      setCompatibilityReport(prev => ({ ...prev, report_Status: 'active' }));
      // await askForMicrophonePermission();
      // await checkForCameraPermission();
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setCompatibilityReport(prev => (
          {
          ...prev,
          audio_permission: 'granted',
          camera_permission: 'granted'
        }));
        stream.getTracks().forEach(track => track.stop());
      } catch (err) {
        console.error('Error with getUserMedia:', err);
        setCompatibilityReport(prev => ({
          ...prev,
          audio_permission: 'denied',
          camera_permission: 'denied'
        }));
      }
    }
  }

  const getGeoLocationPermission = async () => {
    if(isLocation == false){
      getLocationPermission().then((data) => {
        localStorage.setItem('locationLatLong', (data?.e?.coords.latitude + '|' + data?.e?.coords.longitude).toString());
        if (data.permissionObj.state == 'prompt' || data.permissionObj.state == 'granted' ) {
          setCompatibilityReport((prev) => ({
            ...prev,
            location_permission: 'granted'
          }))
        } 
        else {
          setCompatibilityReport((prev) => ({
            ...prev,
            location_permission: 'sys-error'
          }))
        }
        setIsLocation(true);
      }).catch(err => {
        console.log(err);
        /* System/OS location services disabled */
        if (err.code) {
          if (err.code === 1) {
            // permission denied
            setCompatibilityReport((prev) => ({
              ...prev,
              location_permission: 'denied'
            }))
            setIsLocation(true)
          } else {
            // system error
            setCompatibilityReport((prev) => ({
              ...prev,
              location_permission: 'sys-error'
            }))
            setIsLocation(true)
          }
          setIsLocation(true)
        } else {
          setCompatibilityReport((prev) => ({
            ...prev,
            location_permission: 'sys-error'
          }))
        }
        setIsLocation(true)
      });
      // setIsLocation(true);
    }
  }

  const askForMicrophonePermission = async () => {
    try {
      const permissionObj = await navigator.permissions.query({ name: 'microphone' });
      setCompatibilityReport(prev => ({ ...prev, audio_permission: permissionObj.state }));
    } catch (error) {
      console.error('Error asking for microphone permission:', error);
      setCompatibilityReport(prev => ({ ...prev, audio_permission: 'sys-error' }));
    }
  }
  const checkForCameraPermission = async () => {
    try {
      const permissionObj = await navigator.permissions.query({ name: 'camera' });
      setCompatibilityReport(prev => ({ ...prev, camera_permission: permissionObj.state }));
    } catch (error) {
      console.error('Error checking camera permission:', error);
      setCompatibilityReport(prev => ({ ...prev, camera_permission: 'sys-error' }));
    }
  }

  const networkInformation = async () => {
    if (isNetworkReport == false) {
      let fe = '';
      let feffec = '';
      let networkReport = {};
      try {
        let ve = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (void 0 === ve) {
          fe = 'Unknown. Detection not supported or is blocked by browser setting(s)/extension(s).';
        } else {
          fe = ve.type;
          feffec = ve.effectiveType;
        }
        networkReport.network_type = fe;
        networkReport.network_effective_type = feffec;
      } catch (e) {
        networkReport.network_type = 'Unknown. Detection blocked by browser setting(s)/extension(s)';
        networkReport.network_effective_type = 'Unknown. Detection blocked by browser setting(s)/extension(s)';
      }
      getVKYCNetworkDetails(taskId).then((response) => {
        if (response.data && response.data.ip) {
          networkReport.ip_info = response.data;
        }
        initiateSpeedDetection().then((response) => {
          networkReport.network_speed = response;
          setNetworkReport((prev) => ({
            ...prev,
            networkReport
          }))
          // resolve(networkReport);
          setIsNetworkReport(true);
        });
      });
    }
  }

  const osInfo = async () => {
    try {
      if (DetectRTC) {
        setSystemReport((prev) => ({
          ...prev,
          browser: DetectRTC.browser,
          is_safari: DetectRTC.browser.isSafari,
          is_chrome: DetectRTC.browser.isChrome,
          is_incognito: DetectRTC.browser.isPrivateBrowsing,
          os: {
            ...prev?.os,
            version: DetectRTC?.osVersion,
            name: DetectRTC?.osName,
            platform_type: DetectRTC?.isMobileDevice ? 'Mobile' : 'desktop'
          },
          io: {
            ...prev?.io,
            has_microphone: DetectRTC.hasMicrophone,
            n_ai_devices: DetectRTC.audioInputDevices.length,
            ai_devices: DetectRTC.audioInputDevices,
            has_speakers: DetectRTC.hasSpeakers,
            n_ao_devices: DetectRTC.audioOutputDevices.length,
            ao_devices: DetectRTC.audioOutputDevices,

            has_webcam: DetectRTC.hasWebcam,
            n_vi_devices: DetectRTC.videoInputDevices.length,
            vi_devices: DetectRTC.videoInputDevices,
            resolution: DetectRTC.displayResolution,
          }
        }))
        try {
          let Un = navigator.cpuClass;
          if (Un) {
            switch (Un) {
              case 'x86':
                Un = 'x86 (32-bit)';
                break;
              case 'x64':
                Un = 'x64 (64-bit)';
            }
            setSystemReport((prev) => ({
              ...prev,
              os: {
                ...prev.os,
                architecture: Un,
              }
            }))
          }
        } catch (e) {
        }
        try {
          let jn = navigator.deviceMemory;
          setSystemReport((prev) => ({
            ...prev,
            os: {
              ...prev.os,
              ram_size: (jn ? 8 <= jn ? jn + ' GB or more' : jn + ' GB' : 'Unknown. Detection not supported or is blocked by browser setting(s)/extension(s).')
            }
          }))
        } catch (e) {
        }
        let ht = 0;
        // @ts-ignore
        let e = screen.orientation || screen.mozOrientation || screen.msOrientation;
        let orientation = '';
        void 0 !== e ? 'portrait-primary' === e.type || 'portrait-secondary' === e.type ?
          orientation = 'Portrait' : 'landscape-primary' === e.type || 'landscape-secondary' === e.type ?
            orientation = 'Landscape' : orientation = 'Unknown. Could not detect.' : void 0 !== window.orientation ?
          'Tablet' === this.a ? 0 == window.orientation || 180 == window.orientation ?
            orientation = 'Landscape' : 90 == window.orientation || -90 == window.orientation ?
              orientation = 'Portrait' :
              orientation = 'Unknown. Detection not supported or is blocked by browser setting(s)/extension(s).' :
            0 == window.orientation || 180 == window.orientation ? orientation = 'Portrait' : 90 == window.orientation ||
              -90 == window.orientation ? orientation = 'Landscape' : orientation = 'Unknown. Could not detect.' :
          orientation = 'Unknown. Detection not supported or is blocked by browser setting(s)/extension(s).';
        setSystemReport((prev) => ({
          ...prev,
          screen: {
            ...prev.screen,
            orientation: orientation
          }
        }))
        try {
          ht = window.devicePixelRatio || 1;
        } catch (e) {
          ht = 0;
        }
        setSystemReport((prev) => ({
          ...prev,
          screen: {
            ...prev.screen,
            pixel_ration: ht
          }
        }))
        setIsSystemReport(true)
        // resolve(systemReport);
      } else {
        // reject("Detect RTC is not detected.");
      }
    } catch (e) {
      setIsSystemReport(true)
      // resolve();
    }

  }

  const checkWebSocketsSupport = async () => {
    const webtrc = {
      is_supported: DetectRTC.isWebRTCSupported,
      is_screen_capturing_supported: DetectRTC.isScreenCapturingSupported, // Chrome, Firefox, Opera, Edge and Android
      is_srtc_dc_supported: DetectRTC.isSctpDataChannelsSupported,
      is_rtp_dc_supported: DetectRTC.isRtpDataChannelsSupported,
      is_ac_supported: DetectRTC.isAudioContextSupported,
      is_desktop_capturing: DetectRTC.isDesktopCapturingSupported,
      is_ws_supported: DetectRTC.isWebSocketsSupported,
      is_ws_blocked: DetectRTC.isWebSocketsBlocked,
      c_stream_capturing: DetectRTC.isCanvasSupportsStreamCapturing,
      v_stream_capturing: DetectRTC.isVideoSupportsStreamCapturing,
    };
    setCompatibilityReport(prev => ({
      ...prev,
      webtrc
    }));
  };


  const buildSystemReport = async () => {
    if (isSystemReport == false) {
      await osInfo();
      setSystemReport(prevReport => {
        let newReport = { ...prevReport };
        if (newReport.hasOwnProperty('io') && newReport.hasOwnProperty('os')) {
          newReport.has_error = false;
          newReport.report_Status = 'active';

          if (newReport.is_incognito) {
            newReport.has_error = true;
            newReport.solution_message = 'You are in incognito mode please open website in normal window.';
            return newReport;
          }

          if (!newReport.io.has_microphone || !newReport.io.has_speakers || !newReport.io.has_webcam) {
            if (newReport.os.platform_type !== 'Mobile') {
              newReport.has_error = true;
              if (!newReport.is_chrome) {
                newReport.solution_message = 'We are unable to access some system services from this browser, please switch to chrome.';
              } else {
                newReport.solution_message = 'We are unable to access some services on this system, please try on different system.';
              }
            }
          }
        }

        return newReport;
      }
      )
      setIsSystemReport(true);
    }
  }

  const buildNetworkReport = async () => {
    if(isNetworkReport == false){
      await networkInformation();
      networkReport.solution_message = [];
      try {
        await connectivityCheck();
        networkReport.report_Status = 'active';
      } catch (error) {
        networkReport.report_Status = 'active';
        networkReport.has_error = true;
        networkReport.solution_message[0] = 'Some important ports are blocked on your current network. Please switch to other network.';
      }
      setIsNetworkReport(true);
    }
  }

  const connectivityCheck = async () => {
    setNetworkReport(prevReport => ({
      ...prevReport,
      connectivity: {}
    }));


      const connectivityCheckFailure = window.setTimeout(() => {
        setNetworkReport(prevReport => ({
          ...prevReport,
          connectivity: {
            ...prevReport.connectivity,
            error: 'Connectivity timeout error'
          }
        }));
        return new Error('connectivity timeout');
      }, 10000);

      const pc1 = new RTCPeerConnection(options);
      const pc2 = new RTCPeerConnection(options);

    pc2.onicecandidate = (event) => {
      if (event.candidate) {
        setNetworkReport((prevReport) => ({
          ...prevReport,
          connectivity: {
            ...prevReport.connectivity,
            pc_1_candidate: 'pc2 ICE candidate',
          },
        }));
        pc1.addIceCandidate(event.candidate).catch((e) => console.error('Failed to add ICE candidate to pc1:', e));
      }
    };
    pc1.onicecandidate = (event) => {
      if (event.candidate) {
        setNetworkReport((prevReport) => ({
          ...prevReport,
          connectivity: {
            ...prevReport.connectivity,
            pc_2_candidate: 'pc1 ICE candidate',
          },
        }));
        pc2.addIceCandidate(event.candidate).catch((e) => console.error('Failed to add ICE candidate to pc2:', e));
      }
    };

    pc2.ondatachannel = (event) => {
      const receiveChannel = event.channel;
      receiveChannel.onopen = () => {
        messageQueue2.forEach((msg) => receiveChannel.send(msg));
      };
    };

    pc2.onconnectionstatechange = () => {
      if (pc2.connectionState === 'connected') {
        setNetworkReport((prevReport) => ({
          ...prevReport,
          connectivity: {
            ...prevReport.connectivity,
            pc_2_answer: 'pc2 handle answer',
          },
        }));
      }
    };

    const dataChannel = pc1.createDataChannel('testChannel');

    const messageQueue = Array.from({ length: 100 }, (_, i) => `message ${i}`);
    const messageQueue2 = Array.from({ length: 100 }, (_, i) => `message ${i}`);

    dataChannel.onmessage = (msgEvent) => {
      const message = messageQueue.find((msg) => msg === msgEvent.data);
      if (message) {
        const updatedQueue = messageQueue.filter((msg) => msg !== message);
        setMessagesReceived((prev) => prev + 1);
        if (updatedQueue.length === 0) {
          window.clearTimeout(connectivityCheckFailure);
        }
      }
    };

    return () => {
      dataChannel.close();
      pc1.close();
      pc2.close();
    };
  }

  const buildCompatibilityReport = async () => {
    await getGeoLocationPermission();
    await checkForMicrophoneAndCameraPermission();
    await checkWebSocketsSupport();

    if (compatibilityReport.hasOwnProperty('location_permission') && compatibilityReport.hasOwnProperty('audio_permission') && compatibilityReport.hasOwnProperty('camera_permission')) {
      compatibilityReport.has_error = false;
      compatibilityReport.report_Status = 'active';
      compatibilityReport.solution_message = [];
      if (!compatibilityReport.webtrc.is_supported || !compatibilityReport.webtrc.is_ws_supported) {
        compatibilityReport.has_error = true;
        compatibilityReport.solution_message[0] = 'We are unable to access some services on this system, please try on different system.';
        return;
      }
      if (compatibilityReport.location_permission !== 'granted') {
        compatibilityReport.has_error = true;
        if (compatibilityReport.location_permission === 'denied') {
          compatibilityReport.solution_message.push('You have denied us permission to access your location. Please allow it.');
        } else {
          compatibilityReport.solution_message.push('System level error while accessing location services.');
        }
      }
      if (compatibilityReport.audio_permission !== 'granted') {
        compatibilityReport.has_error = true;
        if (compatibilityReport.audio_permission === 'denied') {
          compatibilityReport.solution_message.push('You have denied us permission to access your microphone. Please allow it.');
        } else {
          compatibilityReport.solution_message.push('System level error while accessing microphone.');
        }
      }
      if (compatibilityReport.camera_permission !== 'granted') {
        compatibilityReport.has_error = true;
        if (compatibilityReport.camera_permission === 'denied') {
          compatibilityReport.solution_message.push('You have denied us permission to access your webcam/camera. Please allow it.');
        } else {
          compatibilityReport.solution_message.push('System level error while accessing camera.');
        }
      }
    }
    setIsCompatibilityReport(true);

  }

  return (<Flex
    align="center"
    direction="column"
    css={{ position: "absolute", width: "100%", top: "35%", left: 0 }}>
    <LargeLogo />
    <div style={{ margin: "1rem" }} />
    <Text variant="h4">Singularity Video KYC</Text>
    <div style={{ margin: "1rem" }} />
    {((!error) &&!( compatibilityReport?.location_permission == 'sys-error' )  &&!( compatibilityReport?.location_permission == 'denied' )) &&    <Loading size={20} />}
    <div style={{ margin: "0.5rem" }} />
    { (compatibilityReport?.location_permission == 'denied' || compatibilityReport?.location_permission == 'sys-error') ?
      <Text style={{color: 'red'}}>{'Unable to fetch Location'}</Text>
      : <></>
    }
    {error ?
      <Text style={{color: 'red'}}>{error}</Text>
      : <></>
    }
  </Flex>);
}

const Container = styled("div", {
  width: "100%", ...flexCenter, flexDirection: "column", px: "$10",
});

WelcomePage.displayName = "WelcomePage";


export function initiateSpeedDetection() {
  return new Promise((resolve, reject) => {
    const downloadSize = 2707459;
    let imageAddr = 'https://upload.wikimedia.org/wikipedia/commons/a/a6/Brandenburger_Tor_abends.jpg';
    let download = new Image();
    download.onload = () => {
      const endTime = (new Date()).getTime();
      let duration = (endTime - startTime) / 1000;
      let bitsLoaded = downloadSize * 8;
      let speedBps = (bitsLoaded / duration).toFixed(2);
      // @ts-ignore
      let speedKbps = (speedBps / 1024).toFixed(2);
      // @ts-ignore
      let speedMbps = (speedKbps / 1024).toFixed(2);
      resolve(speedMbps);
    };
    const startTime = (new Date()).getTime();
    let cacheBuster = '?nnn=' + startTime;
    download.src = imageAddr + cacheBuster;
  });
}

export function initiatePusher() {
  
}

export default WelcomePage;
