/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */
const fetchWithRetry = async (url, options) => {
  const MAX_RETRIES = 4;
  let error = Error("something went wrong");
  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      return await fetch(url, options);
    } catch (err) {
      error = err;
    }
  }
  console.error("Fetch failed after max retries", { url, options });
  throw error;
};

export default async function validateToken(interToken) {
  try {
    const response = await fetchWithRetry(
      `${process.env.REACT_APP_API_ENDPOINT}/login-via-intermediate-token`,
      {
        method: "POST",
        body: JSON.stringify({
          intermediate_token: interToken,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.response = response;
      throw error;
    }

    await response.json().then(data => {
      const token = data?.token;
      // response will be success and token is null when url is valid but response
      // domain is not present in 100ms
      if (token === null && token) {
        throw Error(data.status);
      } else {
        localStorage.setItem("authToken", token);
      }
      return token;
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function validateBearerToken(bearer) {
  try {
    const response = await fetchWithRetry(
      `${process.env.REACT_APP_API_ENDPOINT}/validate-token`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearer,
        },
      }
    );

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.response = response;
      throw error;
    }

    const data = await response.json();
    const { token } = data;
    // response will be success and token is null when url is valid but response
    // domain is not present in 100ms
    if (token === null) {
      throw Error(data.msg);
    }
    return token;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
