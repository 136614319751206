import React from "react";
import {Box, Flex, Text, useTheme} from "@100mslive/roomkit-react";

function ErrorPage({error}) {
    const themeType = useTheme().themeType;
    return (
        <Flex
            align="center"
            justify="center"
            css={{
                size: "100%",
                color: "$on_surface_high",
                backgroundColor: "$background_default",
            }}
        >
            <Box css={{position: "relative", overflow: "hidden", r: "$3"}}>
                <img
                    src={
                        themeType === "dark"
                            ? require("../images/error-bg-dark.svg")
                            : require("../images/error-bg-light.svg")
                    }
                    alt="Error Background"
                />
                <Flex
                    align="center"
                    direction="column"
                    css={{position: "absolute", size: "100%", top: "33.33%", left: 0}}
                >
                    <Text variant="h4" css={{mt: "1.75rem"}}>
                        {error}
                    </Text>
                </Flex>
            </Box>
        </Flex>
    );
}

ErrorPage.displayName = "ErrorPage";

export default ErrorPage;
