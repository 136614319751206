/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */

const fetchWithRetry = async (url, options) => {
  const MAX_RETRIES = 4;
  let error = Error("something went wrong");
  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      return await fetch(url, options);
    } catch (err) {
      error = err;
    }
  }
  console.error("Fetch failed after max retries", { url, options });
  throw error;
};

export default async function apiCallerService(endPoint, method, body = "") {
  try {
    const response = await fetchWithRetry(
      `${process.env.REACT_APP_API_ENDPOINT}/` + endPoint,
      method === "POST"
        ? {
            method: method,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
            body: JSON.stringify(body),
          }
        : {
            method: method,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          }
    );

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.response = response;
      throw error;
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function uploadfile(endPoint, body) {
  try {
    const response = await fetchWithRetry(
      `${process.env.REACT_APP_API_ENDPOINT}/` + endPoint,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          // "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
        body: body,
      }
    );

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.response = response;
      throw error;
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
}
