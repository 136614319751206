/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */
const fetchWithRetry = async (url, options) => {
  const MAX_RETRIES = 4;
  let error = Error("something went wrong");
  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      return await fetch(url, options);
    } catch (err) {
      error = err;
    }
  }
  console.error("Fetch failed after max retries", { url, options });
  throw error;
};

export async function getVKYCTaskDetails(taskId) {
  try {
    const response = await fetchWithRetry(
      `${process.env.REACT_APP_API_ENDPOINT}/` + taskId + "/vkyc-info",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      }
    );

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.response = response;
      throw error;
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function getVKYCNetworkDetails(sessionId) {
  try {
    const response = await fetchWithRetry(
      `${process.env.REACT_APP_API_ENDPOINT}/` +
        "cases/" +
        sessionId +
        "/vkyc/network-check",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      }
    );

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.response = response;
      throw error;
    }
    return await response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
}
