/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */

export default async function getLocationPermission() {
  try {
    return new Promise((resolve, reject) => {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(permissionObj => {
          navigator.geolocation.getCurrentPosition(
            e => {
              if (
                e.coords.latitude &&
                e.coords.longitude &&
                e.coords.accuracy
              ) {
                resolve({ permissionObj, e });
              } else {
                reject(4, "Not able to fetch the location.");
              }
            },
            e => {
              reject(e.code, e.message);
            },
            { enableHighAccuracy: !0, timeout: 1e4, maximumAge: 0 }
          );
        })
        .catch(error => {
          reject(6, error.message);
        });
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function hasLocationPermission() {
  try {
    navigator.geolocation.getCurrentPosition(
      () => {
        return true;
      },
      () => {
        return false;
      }
    );
  } catch (err) {
    return false;
  }
}
